// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


window.jQuery = window.$ = require('jquery');

import bootstrap from 'bootstrap/dist/js/bootstrap'

window.bootstrap = bootstrap;

import 'bootstrap-icons/font/bootstrap-icons.css'

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery-ui")
import 'jquery-ui-dist/jquery-ui.min.css';
import 'jquery-ui-dist/jquery-ui.theme.min.css';

import '@widitrade/cookieconsent/build/cookieconsent'

import 'lightgallery.js'
// import 'lg-video'
// import 'lg-thumbnail'
import 'lightgallery.js/dist/css/lightgallery.css'

var moment = require('bootstrap-daterangepicker-labworks/moment')
import DateRangePicker from "bootstrap-daterangepicker-labworks"
import 'bootstrap-daterangepicker-labworks/daterangepicker.css'
// include moment in global and window scope (so you can access it globally)
global.moment = moment;
window.moment = moment;

// Tom select
import TomSelect from 'tom-select';

window.TomSelect = TomSelect;
import 'tom-select/dist/css/tom-select.css'
import 'tom-select/dist/css/tom-select.bootstrap5.css'

// Full calendar
import {Calendar} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
window.interactionPlugin = interactionPlugin;
window.bootstrap5Plugin = bootstrap5Plugin;

import 'jquery.simple-text-rotator/simpletextrotator.css'
import textrotator from 'jquery.simple-text-rotator';

import "cocoon-js-vanilla";

window.textrotator = textrotator;

import Big from 'big.js/big.js'

window.Big = Big;

// import intlTelInput from 'intl-tel-input';
// import intlTelInput from 'intl-tel-input/build/js/intlTelInput.min'
import intlTelInput from 'intl-tel-input/build/js/intlTelInput-jquery.min';
import utilsScript from 'intl-tel-input/build/js/utils';

window.intlTelInput = intlTelInput;
window.utilsScript = utilsScript;
import 'intl-tel-input/build/css/intlTelInput.min.css'

document.addEventListener('turbolinks:load', function () {
    const phoneInput = $('.phone_input');
    window.phoneInput = phoneInput;

    // preferredCountries: ["sk", "cs", "gb", "de", "us"],
    window.intlTelInput = phoneInput.intlTelInput({
        utilsScript: utilsScript,
        preferredCountries: ["sk"]
    });

    phoneInput.on("blur", function () {
        if (phoneInput.intlTelInput("isValidNumber")) {
            // the phone number is valid
            // console.log("Valid phone number");
        } else {
            // the phone number is not valid
            // console.log("Invalid phone number");
            $('.customer-form').addClass('was-validated');
        }
    });
})


var selFromMainSearch = false;
window.selFromMainSearch = selFromMainSearch;

var itemTemplatesSelect = null;
window.itemTemplatesSelect = itemTemplatesSelect;

document.addEventListener('turbolinks:load', function () {
    // console.log('text decorator');
    // Options are dissolve (default), fade, flip, flipUp, flipCube, flipCubeUp and spin.
    $(".rotate").textrotator({
        animation: "flipUp",
        separator: "|", // If you don't want commas to be the separator, you can define a new separator (|, &, * etc.) by yourself using this field.
        speed: 3000 // How many milliseconds until the next word show.
    });
    // console.log('text decorator activated');
})

// wherever date range picker is initialized it should be set to false
var dateSet = false;

$(document).on('change', '#filter_date', function (event) {
    if (!dateSet) {
        dateSet = true; // This sets the flag the first time to prevent immediate submission.
        return; // Prevent the form submission on initial load
    }
    $('#submit-report').click();
});

// when checkbox .in-stock-checkbox, click button .search-car  
$(document).on('change', '.in-stock-checkbox', function () {
    $('.search-car').click();
});


$(document).on('turbolinks:load', function () {

    const loader = $('#loader');
    window.loader = loader;

    // alert('sdf');
    const checkbox = document.getElementById('workshop_send_free_messages');
    const content = document.getElementById('bulkgateContent');

    if (checkbox) {
        // Initial state
        toggleContent(checkbox.checked);

        checkbox.addEventListener('change', function () {
            toggleContent(this.checked);
        });
    }


    function toggleContent(isChecked) {
        if (isChecked) {
            content.classList.add('d-none');
        } else {
            content.classList.remove('d-none');
        }
    }
});

var renderCalendarForm = function (carDetails, form) {
    var calendarHolder = $('#index-calendar-holder');
    var editPlannedHolder = $('#edit-planned-holder');

    calendarHolder.removeClass('col-12');
    calendarHolder.addClass('col-md-8');

    editPlannedHolder.addClass('col-md-4');

    if (carDetails) {
        editPlannedHolder.html('<div id="calendar-car-detail">' + carDetails + '</div><hr>' + form);
    } else {
        editPlannedHolder.html(form);
    }

    if (typeof calendarIndex !== 'undefined') {
        // calendarIndex is available
        calendarIndex.updateSize();
    } else if (typeof calendar !== 'undefined') {
        // calendar is available
        calendar.updateSize();
    }
}
window.renderCalendarForm = renderCalendarForm;

var updateCalendarEvents = function (cal, eventsData) {
    // Remove existing event sources
    cal.getEventSources().forEach(function (eventSource) {
        eventSource.remove();
    });

    // Add new events
    console.log('[updateCalendarEvents] Adding new events to calendar: ' + eventsData);
    cal.addEventSource(eventsData);
}
window.updateCalendarEvents = updateCalendarEvents;

// updates a form input with the current calendar view type when a specific button is clicked
$(document).on('click', '#save_planned_record', function () {
    // Check if 'calendarIndex' is defined and has the 'view' property
    if (typeof calendarIndex !== 'undefined' && calendarIndex.view) {
        // console.log('calendarIndex.view: ' + calendarIndex.view.type);
        $('#service_record_calendar_view').val(calendarIndex.view.type);
    }
    // Else if, check if 'calendar' is defined and has the 'view' property
    else if (typeof calendar !== 'undefined' && calendar.view) {
        // console.log('calendar.view: ' + calendar.view.type);
        $('#service_record_calendar_view').val(calendar.view.type);
    } else {
        // console.log('Neither calendarIndex nor calendar is properly defined');
    }
});


// This function will initialize TomSelect for all elements with the 'tom-select' class
var initializeTomSelect = function () {
    document.querySelectorAll('.tom-select').forEach((el) => {
        if (!el.classList.contains('tom-initialized')) {
            new TomSelect(el, {});
            el.classList.add('tom-initialized');
        }
    });
}

window.initializeTomSelect = initializeTomSelect;


// Funkcia na konverziu dátumu na reťazec vo formáte ISO 8601 s lokálnou časovou zónou
function toLocalISOString(date) {
    var tzOffset = -date.getTimezoneOffset(); // V minútach
    var sign = tzOffset >= 0 ? '+' : '-';
    var pad = function (num) {
        return (num < 10 ? '0' : '') + num;
    };

    var offsetHours = Math.floor(Math.abs(tzOffset) / 60);
    var offsetMinutes = Math.abs(tzOffset) % 60;

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        sign + pad(offsetHours) + ':' + pad(offsetMinutes);
}

function getCalendarStartEndDates() {
    let start, end;

    if (typeof calendar !== 'undefined') {
        start = toLocalISOString(calendar.view.activeStart);
        end = toLocalISOString(calendar.view.activeEnd);
    } else if (typeof calendarIndex !== 'undefined') {
        start = toLocalISOString(calendarIndex.view.activeStart);
        end = toLocalISOString(calendarIndex.view.activeEnd);
    }

    return [start, end]; // Returns an array with start and end dates or undefined if both calendars are not defined
}

document.addEventListener('turbolinks:load', function () {
    const wCurrency = $('#w-currency').text();
    const wLocale = $('#w-locale').text();
    if ($('#w-time-translations').length) {
        const timeTranslations = JSON.parse($('#w-time-translations').attr('data-translations'));
        window.timeTranslations = timeTranslations;
    }

    window.wCurrency = wCurrency;
    window.wLocale = wLocale;

    function humanizeDuration(minutes) {
        if (minutes === 0) {
            return minutes + " " + timeTranslations.minutes.other;
        }

        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;

        var parts = [];
        if (hours > 0) {
            var hoursLabel = hours === 1 ? timeTranslations.hours.one :
                (hours < 5 ? timeTranslations.hours.few : timeTranslations.hours.other);
            parts.push(hours + " " + hoursLabel);
        }
        if (remainingMinutes > 0) {
            var minutesLabel = remainingMinutes === 1 ? timeTranslations.minutes.one :
                (remainingMinutes < 5 ? timeTranslations.minutes.few : timeTranslations.minutes.other);
            parts.push(remainingMinutes + " " + minutesLabel);
        }

        return parts.join(' ');
    }

    window.humanizeDuration = humanizeDuration;

    initializeTomSelect();

    // automatically submit calendar workplaces form when dropdown is changed
    if ($('#filter_workplace_ids').length) {
        $('#filter_workplace_ids').on('change', function () {
            var [start, end] = getCalendarStartEndDates();
            console.log('filter_workplace_ids change: ' + start + ' - ' + end);
            $('#filter_start_date').val(start);
            $('#filter_end_date').val(end);
            $('.submit-workplace_ids').click();
        });
    }

    // automatically submit calendar users form when dropdown is changed
    if ($('#filter_users_ids').length) {
        $('#filter_users_ids').on('change', function () {
            var [start, end] = getCalendarStartEndDates();
            console.log('filter_users_ids change: ' + start + ' - ' + end);
            $('#filter_start_date').val(start);
            $('#filter_end_date').val(end);
            $('.submit-workplace_ids').click();
        });
    }

    // car make dropdown (AUDI, FORD, etc...)
    if ($('.dropdown-car-lists').length) {
        const carsList = new TomSelect(".dropdown-car-lists", {
            // valueField: 'name',
            // labelField: 'name',
            create: true
        });
        window.carsList = carsList;
    }

    // car models dropdown (A4, Bronco, etc...)
    if ($('.dropdown-car-model-lists').length) {
        const carsModelsList = new TomSelect('.dropdown-car-model-lists', {
            create: true
        })
        window.carsModelsList = carsModelsList;
    }

    if ($('.car_customer').length) {
        const customerSelect = new TomSelect('.car_customer', {
            plugins: {
                'clear_button': {
                    'title': 'Remove all selected options',
                }
            }
        })
        window.customerSelect = customerSelect;
    }
})

// CALENDAR FOR LISTING ALL EVENTS - index
// this calendar can edit existing events and cannot add new events
// ACCEPTANCE CRITERIA - TODO; write tests based on below
//
document.addEventListener('turbolinks:load', function () {

    if ($('#calendar-index').length) {
        var isEditable = $('#calendar-index').data('calendar-editable');

        const calendarIndexEl = document.getElementById('calendar-index')
        const locale = calendarIndexEl.dataset.locale;
        const workshopId = calendarIndexEl.dataset.workshopId;
        // const selectedWorkplaces = calendarIndexEl.dataset.selectedWorkplaceIds.split(',');
        const calendarIndex = new Calendar(calendarIndexEl, {
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin],
            firstDay: 1,
            scrollTime: "8:00:00",
            themeSystem: 'bootstrap5',
            initialView: 'timeGridWeek',
            datesSet: function (dateInfo) {
                let selectedWorkplaces;
                let selectedUsers;

                // debugger;
                if ($('#filter_workplace_ids').length) {
                    let workplacesValues = $('#filter_workplace_ids').val();
                    // if nothing is selected
                    if (!workplacesValues.length) {
                        let options = $('#filter_workplace_ids option').length; // Check if there are any options

                        // remove the placeholder option
                        options = options - 1;
                        // if only one option, use it's value
                        if (options === 1) {
                            // Only one option available, select second as first one is placeholder
                            let onlyOptionValue = $('#filter_workplace_ids option').eq(1).val();
                            $('#filter_workplace_ids').val(onlyOptionValue); // Set the only option as selected
                            workplacesValues = [onlyOptionValue]; // Now this will have the only option's value
                        }
                    }
                    selectedWorkplaces = workplacesValues;
                }

                if ($('#filter_users_ids').length) {
                    let usersValues = $('#filter_users_ids').val();
                    // if nothing is selected
                    if (!usersValues.length) {
                        let options = $('#filter_users_ids option').length; // Check if there are any options

                        // remove the placeholder option
                        options = options - 1;
                        // if only one option, use it's value
                        if (options === 1) {
                            // Only one option available, select second as first one is placeholder
                            let onlyOptionValue = $('#filter_users_ids option').eq(1).val();
                            $('#filter_users_ids').val(onlyOptionValue); // Set the only option as selected
                            usersValues = [onlyOptionValue]; // Now this will have the only option's value
                        }
                    }
                    selectedUsers = usersValues;
                }

                console.log('#calendarIndex datesSet: ' + dateInfo.startStr + ' - ' + dateInfo.endStr + ' - ' + locale + ' - ' + workshopId + ' - ' + selectedWorkplaces);
                loadCalendarEvents(this, dateInfo.startStr, dateInfo.endStr, locale, workshopId, selectedWorkplaces, selectedUsers);
            },
            // editable: true,
            editable: isEditable,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,listWeek'
            },
            eventClick: function (info) {
                // console.log('calendarIndex eventClick');
                let event = info.event;
                let entityType = event.extendedProps.car_id ? 'car' : 'customer';
                // debugger;
                let entityId = event.extendedProps.car_id || event.extendedProps.customer_id;

                $.ajax({
                    type: 'GET',
                    url: '/' + event.extendedProps.workshop_id + '/' + entityType + '/' + entityId + '/calendar_events/' + event.extendedProps.record_id + '/edit'
                })
            },
            eventDrop: function (info) {
                // console.log('calendarIndex eventDrop');
                let event = info.event;
                let entityType = event.extendedProps.car_id ? 'car' : 'customer';
                let entityId = event.extendedProps.car_id || event.extendedProps.customer_id;

                // console.log('event_start: ' + event.start);
                // console.log('event_end: ' + event.end);
                // console.log('with toISOString');
                // console.log('event_start: ' + event.start.toISOString());
                // console.log('event_end: ' + event.end.toISOString());

                $.ajax({
                    type: 'PATCH',
                    url: '/' + event.extendedProps.workshop_id + '/' + entityType + '/' + entityId + '/calendar_events/' + event.extendedProps.record_id,
                    data: {
                        car_service_record: {
                            start_at: event.start.toISOString(),
                            end_at: event.end.toISOString()
                        }
                    }
                })
            },
            eventResize: function (info) {
                // console.log('calendarIndex eventResize');
                let event = info.event;
                let entityType = event.extendedProps.car_id ? 'car' : 'customer';
                let entityId = event.extendedProps.car_id || event.extendedProps.customer_id;

                $.ajax({
                    type: 'PATCH',
                    url: '/' + event.extendedProps.workshop_id + '/' + entityType + '/' + entityId + '/calendar_events/' + event.extendedProps.record_id,
                    data: {
                        car_service_record: {
                            start_at: event.start.toISOString(),
                            end_at: event.end.toISOString()
                        }
                    }
                })
            }
        });
        calendarIndex.render();
        window.calendarIndex = calendarIndex;
    }
});


function loadCalendarEvents(calendar, start, end, locale, workshopId, selectedWorkplaces, selectedUsers) {
    $.ajax({
        url: '/' + locale + '/' + workshopId + '/calendar_events',
        method: 'GET',
        dataType: 'json',
        data: {
            start: start,
            end: end,
            selected_workplace_ids: selectedWorkplaces,
            selected_users_ids: selectedUsers
        },
        success: function (data) {
            updateCalendarEvents(calendar, data);

            // if condition didn't work when both calendars were initialized // if (typeof calendarIndex !== 'undefined') { //     updateCalendarEvents(calendar, data); // } else if (typeof calendar !== 'undefined') {
            //     updateCalendarEvents(calendar, data);
            // }
        }
    });
}


var eventStart = null;
var eventEnd = null;
// initializing empty var, will be used to store new event
var newEventShared = null;
var keepEventInCalendar = false;

document.addEventListener('turbolinks:load', function () {
    var modalClosed = false; // Pridaná premenná na sledovanie stavu modálneho okna
    // Odstránenie predchádzajúcich obslužných programov udalostí, aby sa zabránilo viacnásobnému spúšťaniu
    $(document).off('click', '.workplace-btn').on('click', '.workplace-btn', function () {
        keepEventInCalendar = true;
        var workplaceId = $(this).attr('class').match(/workplace-(\d+)/)[1];
        $('#hidden-workplace_id').val(workplaceId);
        $('.submit-form-btn').click();

        // Resetujeme stav modálneho okna pri každom otvorení
        modalClosed = false;

        setTimeout(function () {
            if (!modalClosed) {
                // console.log("Closing modal");
                $('#newEventModal').modal('hide');
            }
        }, 10); // Môžete upraviť oneskorenie podľa potreby
    });

    // To isté platí pre obslužný program udalostí 'hidden.bs.modal'
    // Najprv odstránime obslužný program udalosti, ak už existuje
    $(document).off('hidden.bs.modal').on('hidden.bs.modal', function (event) {
        if (event.target.id === 'newEventModal') {
            modalClosed = true; // Aktualizujeme stav na zatvorené
            // console.log('Triggering event: hidden.bs.modal');
            // console.log("keepEventInCalendar is:", keepEventInCalendar);

            if (newEventShared && !keepEventInCalendar) {
                newEventShared.remove();
                // console.log("Event removed on: hidden.bs.modal");
                newEventShared = null;
            }

            keepEventInCalendar = false;
        }
    });
});


// show spinner and disable button on form submit with id: txt-messages-form
$(document).on('submit', '#txt-messages-form', function () {
    console.log('disable button and show spinner');
    $('#loading-spinner').removeClass('d-none');
    $('.submit-txt-form-btn').prop('disabled', true);
});

document.addEventListener('turbolinks:load', function () {

    var isEditable = $('#calendar').data('calendar-editable');

    // CALENDAR FOR NEW EVENTS
    // have different behaviour than initial calendar which showing all events
    // ACCEPTANCE CRITERIA - TODO; write tests based on below
    // - calendar is opened with new event/record form and car details on the right side
    // - new event can be added by clicking the calendar
    // - existing event can be moved but right side form should stay for chosen car
    // - clicking existing event on the calendar should not override ride side event form
    if ($('#calendar').length) {
        const calendarEl = document.getElementById('calendar');
        const locale = calendarEl.dataset.locale;
        const workshopId = calendarEl.dataset.workshopId;
        const calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrap5Plugin],
            firstDay: 1,
            // TODO; FIX
            // https://stackoverflow.com/questions/65059614/fullcalendar-scrolltime-method-is-not-working
            // scrollTime :  "8:00:00",
            themeSystem: 'bootstrap5',
            initialView: 'timeGridWeek',
            datesSet: function (dateInfo) {
                let selectedWorkplaces;
                let selectedUsers;

                if ($('#filter_workplace_ids').length) {
                    let workplacesValues = $('#filter_workplace_ids').val();
                    // if nothing is selected
                    if (!workplacesValues.length) {
                        let options = $('#filter_workplace_ids option').length; // Check if there are any options

                        // remove the placeholder option
                        options = options - 1;
                        // if only one option, use it's value
                        if (options === 1) {
                            // Only one option available, select second as first one is placeholder
                            let onlyOptionValue = $('#filter_workplace_ids option').eq(1).val();
                            $('#filter_workplace_ids').val(onlyOptionValue); // Set the only option as selected
                            workplacesValues = [onlyOptionValue]; // Now this will have the only option's value
                        }
                    }
                    selectedWorkplaces = workplacesValues;
                }

                if ($('#filter_users_ids').length) {
                    let usersValues = $('#filter_users_ids').val();
                    // if nothing is selected
                    if (!usersValues.length) {
                        let options = $('#filter_users_ids option').length; // Check if there are any options

                        // remove the placeholder option
                        options = options - 1;
                        // if only one option, use it's value
                        if (options === 1) {
                            // Only one option available, select second as first one is placeholder
                            let onlyOptionValue = $('#filter_users_ids option').eq(1).val();
                            $('#filter_users_ids').val(onlyOptionValue); // Set the only option as selected
                            usersValues = [onlyOptionValue]; // Now this will have the only option's value
                        }
                    }
                    selectedUsers = usersValues;
                }

                console.log('#calendar datesSet: ' + dateInfo.startStr + ' - ' + dateInfo.endStr + ' - ' + locale + ' - ' + workshopId + ' - ' + selectedWorkplaces);
                loadCalendarEvents(this, dateInfo.startStr, dateInfo.endStr, locale, workshopId, selectedWorkplaces, selectedUsers);
            },
            views: {
                timeGridWeek: {
                    scrollTime: "8:00:00"
                }
            },
            // height is disabled, I was testing fixed height, however default automatic height based on the width
            // seems to be working fine
            // contentHeight: 400,
            editable: isEditable,
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,listWeek'
            },
            dateClick: function (info) {
                // console.log('calendar DateClick -> adding new event');
                let start = info.dateStr;
                let end = moment(info.dateStr).add(1, 'hour').format();
                eventStart = start;
                eventEnd = end;
                // console.log('start: ' + start);
                // console.log('end: ' + end);
                let existingEvent = calendar.getEventById('newEvent');
                if (existingEvent) {
                    existingEvent.remove();
                }

                let newEvent = calendar.addEventSource([{
                    "id": "newEvent",
                    "extendedProps": {
                        "workshop_id": $('#workshop-id').val(),
                        "car_id": $('#car-id').val()
                    },
                    "title": $('#car-name').val(),
                    "start": start,
                    "end": end
                }]);

                // Store the new event in newEventShared
                newEventShared = newEvent;
                // console.log("Assigned event to newEventShared:", newEventShared);

                let selectedWorkplaces;
                // check if #filter_workplace_ids is present
                if ($('#filter_workplace_ids').length) {
                    let values = $('#filter_workplace_ids').val();
                    // if nothing is selected
                    if (!values.length) {
                        let options = $('#filter_workplace_ids option').length; // Check if there are any options

                        // remove the placeholder option
                        options = options - 1;
                        // if only one option, use it's value
                        if (options === 1) {
                            // Only one option available, select second as first one is placeholder
                            let onlyOptionValue = $('#filter_workplace_ids option').eq(1).val();
                            $('#filter_workplace_ids').val(onlyOptionValue); // Set the only option as selected
                            values = [onlyOptionValue]; // Now this will have the only option's value
                        }
                    }
                    selectedWorkplaces = values;
                } else {
                    // Set to empty array if #filter_workplace_ids is not present
                    selectedWorkplaces = [];
                }


                if (selectedWorkplaces.length === 1 || !$('#filter_workplace_ids').length) {
                    // Only one workplace selected, or filter_workplace_ids not available, proceed with AJAX call
                    $.ajax({
                        type: 'GET',
                        url: '/' + $('#workshop-id').val() + '/car/' + $('#car-id').val() + '/calendar_events/new',
                        data: {
                            car_service_record: {
                                workplace_id: selectedWorkplaces[0], // Set the single selected value or undefined if not available
                                start_at: start,
                                end_at: end,
                                repair_type: $('#car_service_record_repair_type').val(),
                                short_description: $('#car_service_record_short_description').val(),
                                notes: $('#car_service_record_notes').val()
                            }
                        }
                    });
                } else if (selectedWorkplaces.length > 1 || selectedWorkplaces.length === 0) {
                    // Multiple workplaces selected, show alert and do not make AJAX call
                    // console.log('Multiple workplaces selected or empty []: ' + selectedWorkplaces.join(', '));
                    $.ajax({
                        type: 'GET',
                        traditional: true,
                        url: '/' + $('#workshop-id').val() + '/car/' + $('#car-id').val() + '/calendar_events/assign',
                        data: {
                            'car_service_record[workplace_ids][]': selectedWorkplaces, // Correctly assign the array to the nested object
                            'car_service_record[start_at]': start,
                            'car_service_record[end_at]': end,
                            'car_service_record[repair_type]': $('#car_service_record_repair_type').val(),
                            'car_service_record[short_description]': $('#car_service_record_short_description').val(),
                            'car_service_record[notes]': $('#car_service_record_notes').val(),
                            'car_service_record[service_order_id]': $('#car_service_record_service_order_id').val(),
                            'car_service_record[pricing_quote_id]': $('#car_service_record_pricing_quote_id').val()
                        }
                    });
                }
            },
            eventDrop: function (info) {
                // console.log('calendar for new event eventDrop');
                // console.log('calendar eventDrop');
                let event = info.event;

                // console.log('event_start: ' + event.start.toISOString());
                // console.log('event_end: ' + event.end.toISOString());

                if (event.id === 'newEvent') {
                    $.ajax({
                        type: 'GET',
                        url: '/' + $('#workshop-id').val() + '/car/' + $('#car-id').val() + '/calendar_events/new',
                        data: {
                            car_service_record: {
                                workplace_id: $('#car_service_record_workplace_id').val(), // Set the single selected value
                                start_at: event.start,
                                end_at: event.end,
                                repair_type: $('#car_service_record_repair_type').val(),
                                short_description: $('#car_service_record_short_description').val(),
                                notes: $('#car_service_record_notes').val()
                            }
                        }
                    })
                } else {
                    $.ajax({
                        type: 'PATCH',
                        url: '/' + event.extendedProps.workshop_id + '/car/' + event.extendedProps.car_id + '/calendar_events/' + event.extendedProps.record_id,
                        data: {
                            car_service_record: {
                                start_at: event.start.toISOString(),
                                end_at: event.end.toISOString()
                            }
                        }
                    })
                }
                // alert(info.event.id + ' ' + info.event.title + " was dropped on " + info.event.start.toISOString());
                // if (!confirm("Are you sure about this change?")) {
                //     info.revert();
                // }
            },
            eventResize: function (info) {
                // console.log('calendar eventResize');
                let event = info.event;
                if (event.id === 'newEvent') {
                    $.ajax({
                        type: 'GET',
                        url: '/' + $('#workshop-id').val() + '/car/' + $('#car-id').val() + '/calendar_events/new',
                        data: {
                            car_service_record: {
                                workplace_id: $('#car_service_record_workplace_id').val(), // Set the single selected value
                                start_at: event.start.toISOString(),
                                end_at: event.end.toISOString(),
                                repair_type: $('#car_service_record_repair_type').val(),
                                short_description: $('#car_service_record_short_description').val(),
                                notes: $('#car_service_record_notes').val()
                            }
                        }
                    })
                } else {
                    $.ajax({
                        type: 'PATCH',
                        url: '/' + event.extendedProps.workshop_id + '/car/' + event.extendedProps.car_id + '/calendar_events/' + event.extendedProps.record_id,
                        data: {
                            car_service_record: {
                                start_at: event.start.toISOString(),
                                end_at: event.end.toISOString()
                            }
                        }
                    })
                }


                // alert(info.event.id + ' ' + info.event.title + " end is now " + info.event.end.toISOString());
                // if (!confirm("is this okay?")) {
                //     info.revert();
                // }
            }
        });
        calendar.render();
        window.calendar = calendar;
    }
});


Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("packs/car/service_records.js")

require("packs/cars.js")
import copyToClipboard from "./cars";

window.copyToClipboard = copyToClipboard

require("packs/service_record_item.js")
require("packs/photo_recognition.js")
require("packs/stock_items.js")
require("packs/service_orders.js")
require("packs/reservations.js")
// import itemTemplatesSelect from "./service_record_item";
// window.itemTemplatesSelect = itemTemplatesSelect;



